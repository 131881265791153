import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Group, Image, Rect } from 'react-konva'
import { incrementLoader } from 'polotno/utils/loader'
import Konva from 'konva'
import { useColor } from 'polotno/canvas/use-color'

import { ShapeProps } from '../../types'
import { getWarpedImage } from './lib/get-warped-image'
import { getGoogleFonts } from './lib/google-fonts'

export const TextTranformationPathWarp = observer(TextTranformationPathWarpRoot)

function TextTranformationPathWarpRoot(props: ShapeProps) {
  const element = props.element
  const isSelected = props.store.selectedElements.indexOf(element) >= 0
  const elementText = element.text

  const img = useImage(element)

  useLoader(img, element.id)

  function onDragEnd(e: Konva.KonvaEventObject<Event>) {
    const node = e.currentTarget
    element.set({
      x: node.x(),
      y: node.y(),
    })
  }

  function onTransformEnd(e: Konva.KonvaEventObject<Event>) {
    const node = e.currentTarget
    const scale = node.scale() ?? { x: 1, y: 1 }

    node.scaleX(1)
    node.scaleY(1)
    element.set({
      width: element.width * scale.x,
      height: element.height * scale.y,
      fontSize: Math.round(element.fontSize * scale.x),
      x: node.x(),
      y: node.y(),
      rotation: node.rotation(),
    })
  }

  function onSelect() {
    // if we do not check if it's not selected first, then polotno
    // will overwrite all selected elements. this means in a group
    // selection, things can get funky and reset.
    if (!isSelected) {
      props.store.selectElements([element.id])
    }
  }

  return (
    <Group
      draggable={element.draggable}
      hideInExport={!element.showInExport || !elementText}
      x={element.x}
      y={element.y}
      rotation={element.rotation}
      width={element.width}
      height={element.height}
      onDragStart={onSelect}
      onTransformEnd={onTransformEnd}
      onDragEnd={onDragEnd}
      opacity={element.opacity}
      // i don't know what this is really doing, it's just used
      // by polotno everywhere. so i thought it best to copy.
      preventDefault={isSelected}
      id={element.id}
    >
      {img ? (
        <Image
          onClick={onSelect}
          width={element.width}
          height={element.height}
          x={0}
          y={0}
          image={img}
        />
      ) : (
        // the image is loading so let's just show a placeholder of a faint grey box
        <Rect width={element.width} height={element.height} x={0} y={0} fill="#000" opacity={0.1} />
      )}
    </Group>
  )
}

function useImage(element: ShapeProps['element']) {
  const [warpedImage, setWarpedImage] = React.useState<{
    img: HTMLImageElement
    width: number
    height: number
  } | null>(null)
  const color = useColor(element)

  const elementText = element.text
  const custom = element.custom

  React.useEffect(() => {
    let mounted = true
    async function doFontMagic() {
      const url = await getGoogleFonts({
        fontFamily: element.fontFamily,
        fontStyle: element.fontStyle,
        fontWeight: element.fontWeight,
      })

      if (!url) {
        return
      }

      const warpedImage = await getWarpedImage({
        fontUrl: url,
        fontSize: element.fontSize,
        text: elementText,
        bend: custom.bend / 100,
        type: custom.transformation,
        opacity: element.opacity,
        fill: color.fill,
      })

      if (!warpedImage?.img || !mounted) {
        return null
      }

      setWarpedImage(warpedImage)
    }

    doFontMagic()

    return () => {
      mounted = false
    }
  }, [
    color.fill,
    custom.arcType,
    custom.bend,
    custom.d,
    custom.distortH,
    custom.distortV,
    custom.spacing,
    custom.transformation,
    element.fontFamily,
    element.fontSize,
    element.fontStyle,
    element.fontWeight,
    element.letterSpacing,
    element.opacity,
    elementText,
  ])

  // only update width and height if we have an image
  React.useLayoutEffect(() => {
    if (warpedImage) {
      element.set({
        width: warpedImage.width,
        height: warpedImage.height,
      })
    }
    // we don't want element to be in the dependencies array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warpedImage])

  return warpedImage?.img || null
}

function useLoader(img: HTMLImageElement | null, id: string) {
  // tells polotno that we are loading something
  // this helps with studio renderer and thumbnails, so then polotno
  // will wait for our texts to load :)
  React.useEffect(() => {
    if (!img) {
      return incrementLoader(`text-path-warp ${id}`)
    }
  }, [img, id])
}
