import { useStudioProjectDownloadAssetsQuery } from 'codegen/generated/content'
import Konva from 'konva'
import { observer } from 'mobx-react-lite'
import { type IModelType, type IType } from 'mobx-state-tree'
import { ImageElement } from 'polotno/canvas/image-element'
import { type ImageElementType } from 'polotno/model/image-model'
import { StoreType } from 'polotno/model/store'
import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Arc, Group, Rect, Text } from 'react-konva'
import { useParams } from 'react-router-dom'

type ModelString = IType<string | undefined, string, string>
type ModelNumber = IType<number | undefined, number, number>
type ModelBoolean = IType<boolean | undefined, boolean, boolean>

type Props = {
  type: ModelString
  width: ModelNumber
  height: ModelNumber
  src: ModelString
  cropX: ModelNumber
  cropY: ModelNumber
  cropWidth: ModelNumber
  cropHeight: ModelNumber
  cornerRadius: ModelNumber
  flipX: ModelBoolean
  flipY: ModelBoolean
  clipSrc: ModelString
  borderColor: ModelString
  borderSize: ModelNumber
  keepRatio: ModelBoolean
  _cropModeEnabled: ModelBoolean
  store: IType<StoreType | undefined, StoreType, StoreType>
}

type Model = IModelType<Props, object>

export function ContentImageElementModelExtender(model: Model) {
  return model.actions(self => ({
    toggleCropMode(flag?: boolean) {
      self._cropModeEnabled = flag ?? !self._cropModeEnabled

      if (self._cropModeEnabled) {
        self.store.history.startTransaction()
      } else {
        self.store.history.endTransaction()
      }
    },
    beforeDestroy() {
      if (self._cropModeEnabled) {
        self.store.history.endTransaction()
      }
    },
  }))
}

export const ContentImageElementModel = {
  type: 'content-image',
  width: 100,
  height: 100,
  src: '',
  cropX: 0,
  cropY: 0,
  cropWidth: 1,
  cropHeight: 1,
  cornerRadius: 0,
  flipX: false,
  flipY: false,
  clipSrc: '',
  borderColor: 'black',
  borderSize: 0,
  keepRatio: false,
  _cropModeEnabled: false,
}

type ContentImageElementProps = {
  store: StoreType
  element: ImageElementType
  onClick(): void
}

function ContentImageElementContentRoot(props: ContentImageElementProps) {
  const projectId = useCurrentProjectId()
  const element = props.element

  // eslint-disable-next-line local/codegen-query-patterns
  const query = useStudioProjectDownloadAssetsQuery(
    {
      input: {
        projectId,
        ids: [element.custom?.objectId],
      },
    },
    {
      select(data) {
        return data?.studioProjectDownloadAssets?.assets?.[0]?.url
      },
      enabled: Boolean(element.custom?.objectId),
      // don't refresh, as the idea would be to keep the image in memory for the user
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  )

  const src = query.data

  React.useEffect(() => {
    if (!src || element.src === src) {
      return
    }

    element.set({
      src,
    })
  }, [element, src])

  // if the current image does not match the loaded image, then we can return a loader
  if (element.src !== src) {
    return <Loader key="loader" element={element} />
  }

  return <ImageElement {...props} />
}

const ContentImageElementContent = observer(ContentImageElementContentRoot)

export function ContentImageElementShape(props: ContentImageElementProps) {
  return (
    <ErrorBoundary
      fallback={
        <Group
          x={props.element.x}
          y={props.element.y}
          rotation={props.element.rotation}
          listening={false}
          opacity={props.element.a.opacity}
          hideInExport={!props.element.showInExport}
        >
          <Text
            width={props.element.width}
            height={props.element.height}
            text="Oops! Your image failed to load."
            fontFamily="Poppins"
            fontVariant="semibold"
            verticalAlign="middle"
            align="center"
            padding={5}
          />
          <Rect
            width={props.element.width}
            height={props.element.height}
            fill="red"
            opacity={0.2}
          />
        </Group>
      }
    >
      <ContentImageElementContent {...props} />
    </ErrorBoundary>
  )
}

function Loader({ element }: { element: ImageElementType }) {
  const size = Math.min(30, element.a.width / 4, element.a.height / 4)
  const spinnerRef = React.useRef<Konva.Arc>(null)

  React.useEffect(() => {
    const node = spinnerRef.current
    if (!node) {
      return
    }
    const anim = new Konva.Animation(frame => {
      node.rotate((frame?.timeDiff || 0) / 2)
    }, node.getLayer())

    anim.start()
    return () => {
      anim.stop()
    }
  })

  return (
    <Group
      x={element.x}
      y={element.y}
      rotation={element.rotation}
      listening={false}
      opacity={element.a.opacity}
      hideInExport={!element.showInExport}
    >
      <Rect width={element.a.width} height={element.a.height} fill="rgba(124, 173, 212, 0.8)" />
      <Arc
        ref={spinnerRef}
        x={element.a.width / 2}
        y={element.a.height / 2}
        fill="white"
        outerRadius={Math.abs(size)}
        innerRadius={Math.max(1, size - 5)}
        angle={270}
      />
    </Group>
  )
}

function useCurrentProjectId() {
  const { id } = useParams()
  return id
}

ContentImageElementShape.displayName = 'ContentImageElement'
