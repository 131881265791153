import { ShapeType } from 'polotno/model/shape-model'

import { pathWarps } from '../custom-elements/text-transformer/lib/get-warped-image'

export function isTextTransformer(element?: ShapeType) {
  return isTextTransformerCircle(element) || isTextTransformerPathWarp(element)
}

export function isTextTransformerCircle(element: ShapeType) {
  return element?.custom?.transformation === 'circle'
}

export function isTextTransformerPathWarp(element?: ShapeType) {
  return element && pathWarps.has(element?.custom?.transformation)
}
