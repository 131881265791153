import { useStudioProjectDownloadAssetsQuery } from 'codegen/generated/content'
import { loadImage } from 'utils/dom'

import { queryClient } from './query-client'

interface Child {
  type?: string
  fontFamily?: string
  children?: Child[]
  custom?: {
    objectId?: string
  }
}

interface Page {
  children?: Child[]
}

interface Json {
  pages?: Page[]
}

export function getFonts(json: Json): string[] {
  if (!json?.pages || !Array.isArray(json.pages)) {
    return []
  }

  const set = new Set<string>()

  json.pages.forEach(page => {
    if (!Array.isArray(page?.children)) {
      return
    }

    function getFontsOfChildren(children: Child[]) {
      children.forEach(child => {
        if (!child?.type) {
          return
        }

        if (child.type === 'text' && child.fontFamily) {
          set.add(child.fontFamily)
        }

        if (Array.isArray(child.children)) {
          getFontsOfChildren(child.children)
        }
      })
    }

    getFontsOfChildren(page.children)
  })

  return Array.from(set)
}

interface loadContentImageObjectsParams {
  ids: string[]
  projectId: string
}

export async function loadContentImageObjects(
  input: loadContentImageObjectsParams,
): Promise<string[]> {
  const contentImageObjects = await useStudioProjectDownloadAssetsQuery
    .fetcher({
      input,
    })()
    .catch(() => null)

  if (!contentImageObjects?.studioProjectDownloadAssets?.assets?.length) {
    throw new Error(`No studioProjectDownloadAssets found for ${input.ids.join(', ')}`)
  }

  // set react query cache
  queryClient.setQueryData(
    useStudioProjectDownloadAssetsQuery.getKey({ input }),
    contentImageObjects,
  )

  const urls = await Promise.all(
    contentImageObjects.studioProjectDownloadAssets.assets.map(asset =>
      loadImage(asset.url).then(() => asset.url),
    ),
  )

  return urls.filter(url => url)
}

export function getContentImageObjectIds(json: Json): string[] {
  if (!json?.pages || !Array.isArray(json.pages)) {
    return []
  }

  const set = new Set<string>()

  json.pages.forEach(page => {
    if (!Array.isArray(page?.children)) {
      return
    }

    function traverse(children: Child[]) {
      children.forEach(child => {
        if (!child?.type) {
          return
        }

        if (child.type === 'content-image' && child.custom?.objectId) {
          set.add(child.custom.objectId)
        }

        if (Array.isArray(child.children)) {
          traverse(child.children)
        }
      })
    }

    traverse(page.children)
  })

  return Array.from(set)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getData(json: any) {
  if (typeof json?.fileUrl === 'string') {
    const response = await fetch(json.fileUrl)
      .then(res => res.json())
      .then(data => ({ hasError: false as const, data }))
      .catch(e => ({ hasError: true as const, error: e?.message || e }))

    if (response.hasError) {
      // eslint-disable-next-line no-console
      console.error(response.error)
      throw new Error(response.error)
    }

    return response.data
  }

  return json
}

export function until(condition: () => boolean) {
  return new Promise<void>(resolve => {
    function poll() {
      if (condition()) {
        resolve()
      } else {
        setTimeout(poll, 32)
      }
    }
    poll()
  })
}

export function nextFrame() {
  return new Promise<void>(resolve => {
    requestAnimationFrame(() => requestAnimationFrame(() => setTimeout(resolve, 64)))
  })
}
