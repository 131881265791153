import { QueryClientProvider } from '@tanstack/react-query'
import { store } from 'polotno-utils/store'
import { toggleFadeInAnimation } from 'polotno/canvas/use-fadein'
import { WorkspaceCanvas } from 'polotno/canvas/workspace-canvas'
import { setTextOverflow as unstable_setTextOverflow } from 'polotno/utils/flags'
import ReactDOM from 'react-dom/client'

import './globals'
import { queryClient } from './query-client'

toggleFadeInAnimation(false)
unstable_setTextOverflow('change-font-size')

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <QueryClientProvider client={queryClient}>
    <WorkspaceCanvas store={store} components={{ PageControls: () => null, Tooltip: () => null }} />
  </QueryClientProvider>,
)
