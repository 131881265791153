import { unstable_registerShapeComponent, unstable_registerShapeModel } from 'polotno/config'
import createStore from 'polotno/model/store'
import invariant from 'tiny-invariant'

import {
  ContentImageElementModel,
  ContentImageElementModelExtender,
  ContentImageElementShape,
} from './custom-elements/content-image'
import {
  TextTransformerShape,
  TextTransformerModel,
  TextTransformerModelExtender,
} from './custom-elements/text-transformer'

// @ts-expect-error - typings from polotno are not correct :/
unstable_registerShapeComponent('content-image', ContentImageElementShape)
unstable_registerShapeModel(ContentImageElementModel, ContentImageElementModelExtender)

// @ts-expect-error - typings from polotno are not correct :/
unstable_registerShapeComponent('text', TextTransformerShape)
unstable_registerShapeModel(TextTransformerModel, TextTransformerModelExtender)

const POLOTNO_KEY = import.meta.env.VITE_POLOTNO_KEY

invariant(POLOTNO_KEY, 'VITE_POLOTNO_KEY is not set inside polotno-utils/src/store.tsx')

export const store = createStore({ key: POLOTNO_KEY, showCredit: false })
