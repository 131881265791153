import Konva from 'konva'
import { observer } from 'mobx-react-lite'
import { getLineHeight, useFontLoader, usePrevious } from 'polotno/canvas/text-element'
import { useColor } from 'polotno/canvas/use-color'
import * as React from 'react'
import { Circle, Group, TextPath } from 'react-konva'

import { ShapeProps } from '../../types'

export const TextTranformationCircle = observer(TextTranformationCircleRoot)

function TextTranformationCircleRoot(props: ShapeProps) {
  const groupRef = React.useRef<Konva.Group>(null!)
  const textPathRef = React.useRef<Konva.TextPath>(null!)

  const element = props.element
  const isSelected = props.store.selectedElements.indexOf(element) >= 0
  const isOnlySelected = isSelected && props.store.selectedElements.length === 1
  const custom = element.custom
  const invert = Boolean(custom.invert)

  const [fontFamilyLoaded] = useFontLoader(props.store, element.fontFamily)

  // this is to ensure like a better relationship between small and large
  // radiuses. once you get really large into the radiuses (like 500),
  // adding +1 doesn't do a lot. compared to when it's at like 10.
  // so the slider in the UI is very sensitive to low values and not to high ones.
  // this tries to fix that by keeping the low and high values having
  // the same visual effect.
  // 1.4 is just an eyeballed number.
  const radius = Math.pow(custom.radius, 1.4)

  function onDragEnd(e: Konva.KonvaEventObject<Event>) {
    const node = e.currentTarget
    element.set({
      x: node.x(),
      y: node.y(),
    })
  }

  function onTransform(e: Konva.KonvaEventObject<Event>) {
    const node = e.currentTarget
    const scale = node.scale() ?? { x: 1, y: 1 }
    element.set({
      scaleX: scale.x,
      scaleY: scale.y,
    })
  }

  function onTransformEnd(e: Konva.KonvaEventObject<Event>) {
    const node = e.currentTarget
    element.set({
      x: node.x(),
      y: node.y(),
      rotation: node.rotation(),
    })
  }

  const pathData = getPath({ invert, radius })
  const color = useColor(element)

  const elementText = element.text

  const oldFontFamily = usePrevious(element.fontFamily)

  function onSelect() {
    // if we do not check if it's not selected first, then polotno
    // will overwrite all selected elements. this means in a group
    // selection, things can get funky and reset.
    if (!isSelected) {
      props.store.selectElements([element.id])
    }
  }

  const x = element.x
  const y = element.y

  const [fontLoaded] = useFontLoader(props.store, element.fontFamily)

  const textProps = {
    ...color,
    ref: textPathRef,
    data: pathData,
    align: 'center',
    text: elementText,
    x: -radius,
    y: 0,
    stroke: element.stroke,
    lineJoin: 'round',
    strokeWidth: element.strokeWidth,
    fillAfterStrokeEnabled: true,
    fontSize: element.fontSize,
    // use old font family as fallback, until new font is loaded
    fontFamily: `"${element.fontFamily}", "${oldFontFamily}"`,
    fontStyle: element.fontStyle + ' ' + element.fontWeight,
    textDecoration: element.textDecoration,
    letterSpacing: element.letterSpacing * element.fontSize,
    lineHeight: getLineHeight({
      fontLoaded,
      fontFamily: element.fontFamily,
      fontSize: element.fontSize,
      lineHeight: element.lineHeight,
    }),
    onClick: onSelect,
  } as const

  return (
    <Group
      ref={groupRef}
      draggable={element.draggable}
      hideInExport={!element.showInExport || !elementText}
      x={x}
      y={y}
      rotation={element.rotation}
      scaleX={element.scaleX}
      scaleY={element.scaleY}
      onDragStart={onSelect}
      onDragEnd={onDragEnd}
      onTransform={onTransform}
      onTransformEnd={onTransformEnd}
      opacity={element.opacity}
      // i don't know what this is really doing, it's just used
      // by polotno everywhere. so i thought it best to copy.
      preventDefault={isSelected}
      id={element.id}
    >
      {isOnlySelected ? (
        <Group hideInExport>
          <Circle
            x={0}
            y={radius}
            stroke="#5A9FFC"
            strokeWidth={element.scaleX ? 3 / element.scaleX : 3}
            radius={radius}
          />
          {/**
           * invisible elements to match the circle, so the Transformer on the TextPath/Group is even.
           * if you didn't render these, you would not rotate the TextPath around the center of the circle.
           */}
          <TextPath {...textProps} x={radius} y={radius * 2} opacity={0} rotation={180} />
          <TextPath {...textProps} x={radius} y={0} opacity={0} rotation={90} />
          <TextPath {...textProps} x={-radius} y={radius * 2} opacity={0} rotation={270} />
        </Group>
      ) : null}

      {fontFamilyLoaded ? <TextPath {...textProps} /> : null}
    </Group>
  )
}

// godspeed! i would ask chatgpt about this.
// also it looked nicer on one line but prettier is kinda making this look more complicated
// than it really is.
// basically if we are inverting, we want to start at the top of the circle.
// if we are not inverting, we want to start at the bottom of the circle.
function getPath({ invert, radius }: { invert: boolean; radius: number }) {
  if (invert) {
    return `M ${radius} 0 A ${radius},${radius} 0 1,0 ${radius},${
      2 * radius
    } A ${radius},${radius} 0 1,0 ${radius},0`
  }

  return `M ${radius} ${2 * radius} a ${radius},${radius} 0 1,1 0,-${
    2 * radius
  }  a ${radius},${radius} 0 1,1 0,${2 * radius}`
}
