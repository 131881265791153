/* eslint-disable no-console */
/// <reference types="../types.d.ts" />
import { store } from 'polotno-utils/store'
import {
  setTextOverflow as unstable_setTextOverflow,
  setTextVerticalResizeEnabled as unstable_setTextVerticalResizeEnabled,
  useHtmlTextRender as unstable_useHtmlTextRender,
} from 'polotno/utils/flags'
import { addGlobalFont } from 'polotno/utils/fonts'
import { onLoadError, setAssetLoadTimeout } from 'polotno/utils/loader'

import { queryClient } from './query-client'
import {
  getContentImageObjectIds,
  getData,
  getFonts,
  loadContentImageObjects,
  nextFrame,
  until,
} from './utils'

window.store = store

window.config = {
  addGlobalFont,
  unstable_useHtmlTextRender,
  unstable_setTextVerticalResizeEnabled,
  unstable_setTextOverflow,
  onLoadError,
  setAssetLoadTimeout,
}

async function loadProject(json: unknown, projectId = '') {
  store.clear()

  const data = await getData(json)

  const fonts = getFonts(data)
  const contentImageIds = getContentImageObjectIds(data)

  await Promise.allSettled(
    [
      ...(fonts?.map(store.loadFont) || []),
      contentImageIds?.length > 0 && loadContentImageObjects({ ids: contentImageIds, projectId }),
      nextFrame(),
    ].filter(Boolean),
  )

  store.loadJSON(data)
  store.setElementsPixelRatio(1)

  await nextFrame()

  if (queryClient.isFetching()) {
    await until(() => !queryClient.isFetching())
  }

  await nextFrame()

  await store.waitLoading()
}

async function thumbFromCurrentProject(options = {}) {
  return store
    .toDataURL({ pixelRatio: 1, ...options })
    .then(data => data?.split('base64,')?.[1])
    .catch(console.error)
}

window.loadProject = loadProject
window.thumbFromCurrentProject = thumbFromCurrentProject

// for backward compatibility with the old thumb generator
// @ts-expect-error
window.generateThumbnailImageBase64 = async function generateThumbnail(
  json: unknown,
  options = {},
) {
  const data = await getData(json)
  store.loadJSON(data)
  store.setElementsPixelRatio(1)

  await Promise.allSettled([
    ...(getFonts(data)?.map(font => store.loadFont(font)) || []),
    nextFrame(),
  ])

  if (queryClient.isFetching()) {
    await until(() => !queryClient.isFetching())
  }

  await nextFrame()

  await store.waitLoading().catch(console.error)

  const base64String = await store.toDataURL({ pixelRatio: 1, ...options }).catch(console.error)

  return base64String?.split('base64,')?.[1]
}

// @ts-expect-error
window.generateImageBase64 = window.generateThumbnailImageBase64
