export interface Root {
  kind: string
  items: Item[]
}

export interface Item {
  family: string
  variants: (keyof Files)[]
  subsets: string[]
  version: string
  lastModified: string
  files: Files
  category: string
  kind: string
  menu: string
}

export interface Files {
  regular?: string
  italic?: string
  '500'?: string
  '600'?: string
  '700'?: string
  '800'?: string
  '100'?: string
  '200'?: string
  '300'?: string
  '900'?: string
  '100italic'?: string
  '200italic'?: string
  '300italic'?: string
  '500italic'?: string
  '600italic'?: string
  '700italic'?: string
  '800italic'?: string
  '900italic'?: string
}

const GOOGLE_WEBFONTS_API_KEY = 'AIzaSyAUUn9zoGYz7i7jiPJX0KrReYoFGC56QvU'
const url = `https://www.googleapis.com/webfonts/v1/webfonts?key=${GOOGLE_WEBFONTS_API_KEY}`

async function fetcher(): Promise<Root> {
  const response = await fetch(url)

  if (!response.ok) {
    throw new Error('Failed to get fonts')
  }

  const json = await response.json()

  return json
}

let promise: Promise<Root> | null = null

export function getGoogleFontsFetcher() {
  if (!promise) {
    promise = fetcher()
  }

  return promise
}

export const styleAndWeightToGoogleVariants = {
  normalnormal: ['regular'],
  italicnormal: ['italic'],
  normalbold: ['700', '800', '600'],
  italicbold: ['700italic', '800italic', '600italic', 'italic'],
} as const

export async function getGoogleFonts(element: {
  fontFamily: string
  fontStyle: 'normal' | 'italic'
  fontWeight: 'normal' | 'bold'
}) {
  const data = await getGoogleFontsFetcher()

  const family = data.items.find(item => element.fontFamily === item.family)
  const variants = family?.variants
  const files = family?.files

  if (!files || !variants) {
    return null
  }

  const key = `${element.fontStyle}${element.fontWeight}` as const

  const elementFontVariant = findVariant(
    variants,
    ...styleAndWeightToGoogleVariants[key],
    // always fallback to regular
    'regular',
  )

  if (!elementFontVariant) {
    return null
  }

  return family.files[elementFontVariant]
}

function findVariant<F extends keyof Files>(variants: F[], ...options: F[]): F | null {
  for (const option of options) {
    const variant = variants.find(variant => variant === option)
    if (variant) {
      return variant
    }
  }
  return null
}
