import { observer } from 'mobx-react-lite'
import { types, type IModelType, type IType } from 'mobx-state-tree'
import { StoreType } from 'polotno/model/store'
import { TextElement } from 'polotno/canvas/text-element'

import { TextTranformationCircle } from './text-transformation-circle'
import { TextTranformationPathWarp } from './text-transformation-path-warp'
import { ShapeProps } from '../../types'
import { isTextTransformerCircle, isTextTransformerPathWarp } from '../../lib/is-text-transformer'

function TextShapeRoot(props: ShapeProps) {
  if (isTextTransformerCircle(props.element)) {
    return <TextTranformationCircle {...props} />
  }
  if (isTextTransformerPathWarp(props.element)) {
    return <TextTranformationPathWarp {...props} />
  }
  return <TextElement {...props} />
}

export const TextTransformerShape = observer(TextShapeRoot)

// copied from polotno/src/model/text-model.ts#TextElement
export const TextTransformerModel = {
  type: 'text',
  text: '',
  // placeholder is working similar to input placeholder
  // it will be rendered if no text is defined
  // and we will use it in input element too
  // useful for template canvas, where users will need to replace text elements
  placeholder: '',
  fontSize: 14,
  fontFamily: 'Roboto',
  fontStyle: 'normal', // can be normal or italic
  fontWeight: 'normal', // can be normal or bold or some other CSS variations
  textDecoration: '',
  fill: 'black',
  align: 'center',
  width: 100,
  height: 0, // component will set non-zero value after layout calculate
  verticalAlign: 'top',
  strokeWidth: 0,
  stroke: 'black',
  // dir: 'ltr', // can be ltr or rtl
  lineHeight: types.optional(types.union(types.number, types.string), 1.2),
  letterSpacing: 0, // % from font size
  _editModeEnabled: false,

  backgroundEnabled: false,
  backgroundColor: '#7ED321',
  backgroundOpacity: 1,
  backgroundCornerRadius: 0.5, // % from half line height
  backgroundPadding: 0.5, //% from half line height
}

type ModelBoolean = IType<boolean | undefined, boolean, boolean>

type Props = {
  _editModeEnabled: ModelBoolean
  store: IType<StoreType | undefined, StoreType, StoreType>
}

type Model = IModelType<Props, object>

// copied from polotno/src/model/text-model.ts#TextElement
export function TextTransformerModelExtender(model: Model) {
  return model.actions(self => ({
    toggleEditMode(editing?: boolean) {
      self._editModeEnabled = editing ?? !self._editModeEnabled

      if (self._editModeEnabled) {
        self.store.history.startTransaction()
      } else {
        self.store.history.endTransaction()
      }
    },
  }))
}
