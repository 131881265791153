import { fontLoader } from './opentype-font-loader'
import './path-warp'

export type PathWarps =
  | 'arc'
  | 'arcLower'
  | 'arcUpper'
  | 'arch'
  | 'bulge'
  | 'flag'
  | 'fish'
  | 'rise'
  | 'inflate'
  | 'squeeze'
  | 'waveLower'
  | 'waveUpper'

export const pathWarps = new Set<PathWarps>([
  'arc',
  'arcLower',
  'arcUpper',
  'arch',
  'bulge',
  'flag',
  'fish',
  'rise',
  'inflate',
  'squeeze',
  'waveLower',
  'waveUpper',
])

const mappings: Record<PathWarps, string> = {
  arc: 'WARP_ARC',
  arcLower: 'WARP_ARC_LOWER',
  arcUpper: 'WARP_ARC_UPPER',
  arch: 'WARP_ARCH',
  bulge: 'WARP_BULGE',
  flag: 'WARP_FLAG',
  fish: 'WARP_FISH',
  rise: 'WARP_RISE',
  inflate: 'WARP_INFLATE',
  squeeze: 'WARP_SQUEEZE',
  waveLower: 'WARP_WAVE_LOWER',
  waveUpper: 'WARP_WAVE_UPPER',
}

export async function getWarpedImage({
  fontUrl,
  text,
  fontSize,
  type,
  bend,
  opacity,
  fill,
}: {
  fontUrl: string
  text: string
  fontSize: number
  type: PathWarps
  bend: number
  opacity: number
  fill: string
}) {
  const font = fontUrl ? await fontLoader(fontUrl) : null

  if (!font) {
    return null
  }

  const path = font.getPath(text, 0, 0, fontSize)

  const pathBBox = path.getBoundingBox()

  const adjustX = -pathBBox.x1
  const adjustY = -pathBBox.y1

  const adjustedPath = font.getPath(text, adjustX, adjustY, fontSize)
  const svgPath = adjustedPath.toDOMElement(3)

  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svg.appendChild(svgPath)

  // @ts-expect-error
  const p = new __PathWarp(adjustedPath.toPathData(3))

  p.warp({
    type: mappings[type],
    bend: bend,
    distortV: 0,
    distortH: 0,
  })

  svgPath.setAttribute('d', p.output())

  document.body.appendChild(svg)
  const svgBBox = svg.getBBox()
  svg.setAttribute('opacity', `${opacity}`)
  svg.setAttribute('fill', fill)
  svg.setAttribute('width', `${svgBBox.width}`)
  svg.setAttribute('height', `${svgBBox.height}`)
  svg.setAttribute('viewBox', `${svgBBox.x} ${svgBBox.y} ${svgBBox.width} ${svgBBox.height}`)
  document.body.removeChild(svg)

  const img = new window.Image()
  const base64 = btoa(new XMLSerializer().serializeToString(svg))
  img.src = `data:image/svg+xml;base64,${base64}`

  return { img, width: svgBBox.width, height: svgBBox.height }
}
