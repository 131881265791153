import { Font, load } from 'opentype.js/dist/opentype.module'

const fontLoaders = new Map<string, Promise<Font | undefined>>()

export async function fontLoader(fontUrl: string) {
  if (!fontLoaders.has(fontUrl)) {
    fontLoaders.set(
      fontUrl,
      new Promise<Font | undefined>((resolve, reject) =>
        load(fontUrl, (err, font) => (err ? reject(err) : resolve(font))),
      ),
    )
  }

  return fontLoaders.get(fontUrl)
}
